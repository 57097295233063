@import 'colors';

.sv_q_radiogroup_label {
  span {
    margin: 0 2px;
  }
}

.sortable-ghost {
  opacity: .5;
}

@mixin svg-icon() {
  content: "";
  background: no-repeat center;
}

.sv_window {
  position: fixed;
  bottom: 3px;
  right: 10px;
  padding: 1px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}

.sv_window_title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.sv_window_content {
  padding: 8px;
  margin: 0;
}

.sv_window_title a,
.sv_window_title a:link,
.sv_window_title a:visited {
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  color: black;
}

$header-background-color: $cool_c3;
$body-container-background-color: $cool_c0;

$main-color: $primary;
$main-hover-color: darken($main-color, 5%);
$body-background-color: white;
$inputs-background-color: $body-background-color;
$text-color: #6d7072;
$header-color: $text-color;
$border-color: $header-background-color;

$error-color: #ed5565;
$error-background-color: lighten($error-color, 30%);

$header-height: 74%;
$font-size: 14px;

$base-line-height: 2em;

.sv_main.sv_frame {
  .sv_container {
    max-width: 80%;
    margin: auto;
    padding: 0 1em;

    .sv_header {
      padding-top: 5em;
      padding-bottom: 1em;
    }
  }
}

.sv_main {
  background-color: $body-container-background-color;

  hr {
    border-bottom: 1px solid $border-color;
  }

  input[type="button"],
  button {
    color: $body-background-color;
    background-color: $main-color;

    &:hover {
      background-color: $main-hover-color;
    }
  }

  .sv_q_other input,
  .sv_q_text_root,
  .sv_q_dropdown_control,
  input[type="text"],
  select,
  textarea {
    border: 1px solid $border-color;
    color: $text-color;
    background-color: $inputs-background-color;

    &:focus {
      border: 1px solid $main-color;
    }
    &:disabled {
      background-color: $cool_c4;
    }
  }

  .sv_select_wrapper {
    background-color: $body-background-color;
    &:before {
      background-color: $main-color;
    }
  }

  .sv_header {
    color: $header-color;
  }

  .sv_custom_header {
    background-color: $header-background-color;
  }

  .sv_container {
    color: $text-color;
  }

  .sv_body {
    background-color: $body-background-color;
  }

  .sv_progress {
    background-color: $border-color;
  }

  .sv_progress_bar {
    background-color: $main-color;
  }

  .sv_p_root {
    & > .sv_row {
      border-bottom: 1px solid $border-color;

      &:nth-child(odd) {
        background-color: $body-container-background-color;
      }
      &:nth-child(even) {
        background-color: $body-background-color;
      }
    }
  }

  .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
    border: 1px solid $error-color;
    background-color: $error-background-color;
    color: $error-color;

    & + input,
    & + textarea,
    & + .sv_select_wrapper select {
      border: 1px solid $error-color;
      &:focus {
        outline: $error-color auto 5px;
      }
    }
  }

  table.sv_q_matrix,
  table.sv_q_matrix_dropdown,
  table.sv_q_matrix_dynamic {
    td {
      border-bottom: 1px solid $border-color;
      border-top: 1px solid $border-color;
    }

    span {
      font-size: 0.9em;
      font-weight: normal;
    }
  }

  .sv_q_m_cell_selected {
    color: $body-background-color;
    background-color: $main-hover-color;
  }

  .sv_q_rating_item {
    &.active {
      .sv_q_rating_item_text {
        background-color: $main-hover-color;
        border-color: $main-hover-color;
        color: $body-background-color;
      }
    }
    .sv_q_rating_item_text {
      border: 1px solid $border-color;
      &:hover {
        border: 1px solid $main-hover-color;
      }
    }
  }
}

.sv_main {
  position: relative;
  width: 100%;
  //background-color: $body-container-background-color;
  font-family: 'Lato', sans-serif;
  font-size: $font-size;

  hr {
    border: none;
    //border-bottom: 1px solid $header-background-color;
  }

  input[type="button"],
  button {
    font-size: 0.85em;
    font-weight: bold;
    //color: $body-background-color;
    line-height: $base-line-height;
    //background-color: $main-color;
    border: none;
    min-width: 100px;
    min-height: 50px;
    cursor: pointer;
    padding: 0 6px;
    border-radius: 3px;

    &:hover {
      //background-color: $main-hover-color;
    }
  }

  .sv_custom_header {
    position: absolute;
    width: 100%;
    height: $header-height;
    max-height: 275px;
    //background-color: $header-background-color;
  }
  .sv_container {
    position: relative;
    //color: $text-color;

    .sv_header {
      padding: 1em;

      h3 {
        font-size: 2em;
        font-weight: 300;
        text-align: left;
        margin: 0;
      }
    }

    .sv_body {
      &.sv_completed_page {
        text-align: center;
        padding: 5em 1em;

        > h3 {
          margin: 0;
          font-size: 30px;
          font-weight: 300;
        }
      }

      //background-color: $body-background-color;
      padding: 1em 1em 5em 1em;
      //border-top: 2px solid $main-color;

      .sv_progress {
        height: 0.5em;
        //background-color: $header-background-color;
        position: relative;

        .sv_progress_bar {
          height: 100%;
          //background-color: $main-color;
          margin-top: 2em;
        }
        span {
          position: absolute;
          top: -1.8em;
          display: inline-block;
          font-size: 0.9em;
          min-width: 140px;
        }
      }

      .sv_p_root {
        .sv_page_title {
          font-size: 1.5em;
          margin: 12px 0;
          font-weight: normal;
        }

        .sv_p_title {
          font-weight: bold;
          font-size: 1.15em;
          margin: 1.5em 0 0 0;
        }

        & > .sv_row {
          //border-bottom: 1px solid $header-background-color;
          padding: 0;

          &:nth-child(odd) {
            //background-color: $body-background-color;
          }
          &:nth-child(even) {
            //background-color: $body-container-background-color;
          }
        }

        .sv_q_title {
          font-weight: bold;
          font-size: 1em;
          margin: 0.5em 0;
        }

        .sv_q_description {
          margin-top: -0.4em;
          span {
            font-size: 1.4rem;
            line-height: 1.5rem;
            font-weight: 400;
          }
        }

        .sv_q {
          padding: 0.5em 1em 1.5em 1em;
          box-sizing: border-box;
          overflow: auto;

          // matrix dynamic scroll
          .sv_qstn {
            overflow-y: auto;
          }

          .sv_panel_dynamic {
            .sv_q_title {
              font-weight: normal;
            }
          }

          // Signature:
          .sjs_sp_container {
            border: 1px solid $cool_c4;
          }
          .sjs_sp_controls {
            button {
              border-radius: 25px;
              height: 50px;
              width: 50px;
              min-width: 0;
            }
          }

          .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
            //border: 1px solid $error-color;
            //background-color: $error-background-color;
            margin: 1em 0;
            //color: $error-color;
            padding: 1em;

            & + input,
            & + textarea,
            & + .sv_select_wrapper select {
              //border: 1px solid $error-color;
              &:focus {
                //outline: $error-color auto 5px;
              }
            }
          }

          .sv_q_other input,
          .sv_q_text_root,
          .sv_q_dropdown_control,
          input[type="text"],
          select,
          textarea {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            //border: 1px solid $header-background-color;
            font-size: 1em;
            line-height: $base-line-height;
            //color: $text-color;
            padding-left: 1em;
            //background-color: $inputs-background-color;

            &:focus {
              //border-color: $main-color;
              outline: none;
              //border: 1px solid $main-color;
            }
          }

          .sv_q_other input,
          .sv_q_text_root,
          .sv_q_dropdown_control,
          input[type="text"],
          select {
            height: calc(#{$base-line-height} + 1px);
          }

          div.sv_q_text_root,
          div.sv_q_dropdown_control {
            min-height: 2.43em;
            min-width: 7em;
          }

          .sv_select_wrapper {
            width: 100%;
            position: relative;
            display: inline-block;
            //background-color: $body-background-color;

            select {
              display: block;
              background: transparent;
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              padding-right: 2.5em;

              &::-ms-expand {
                display: none;
              }
            }

            &:before {
              padding: 1em;
              position: absolute;
              right: 1px;
              right: 0;
              top: 1px;
              //background-color: $main-color;
              //background-image: url("./images/ArrowDown_34x34.svg");
              @include svg-icon();
            }
          }

          input[type="color"] {
            min-height: $base-line-height;
            padding: 0;
          }

          input[type="radio"],
          input[type="checkbox"] {
            -webkit-transform: scale(1.1, 1.1);
            margin: 0;
            margin-right: 0.55em;
            margin-top: -3px;
            width: 1.2em;
            height: 1.2em;
            vertical-align: middle;
            font-size: 1em;

            &:focus {
              outline: none;
            }
          }

          .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child),
          .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child) {
            margin-right: 1.5em;
          }

          .sv_q_radiogroup {
            padding-top: 6px;
          }

          // .sv_q_radiogroup,
          // .sv_q_checkbox {
          //   $total-columns: 5;
          //   @for $i from 1 through $total-columns {
          //     &.sv-q-col-#{$i} {
          //       width: 100% / $i;
          //       display: inline-block;
          //       @media only screen and (max-width: 480px) {
          //         width: 100%;
          //       }
          //     }
          //   }
          // }

          .sv_q_checkbox_inline,
          .sv_q_radiogroup_inline {
            line-height: $base-line-height;
            display: inline-block;
          }

          .sv_q_footer {
            padding: 1em 0;
          }
        }

        table {
          width: 100%;
          border-collapse: collapse;


          &.sv_q_matrix,
          &.sv_q_matrix_dropdown,
          &.sv_q_matrix_dynamic {
            .sv_matrix_dynamic_button {
              padding: 0.3em 2em;
            }
            tr {
              //border-bottom: 1px solid $header-background-color;
            }
            // word-break on panels
            span {
              word-break: keep-all; 
            }

            .sv_q_other input,
            .sv_q_text_root,
            .sv_q_dropdown_control,
            input[type="text"],
            select,
            textarea {
              position: relative;
              width: 100%;
              box-sizing: border-box;
              font-size: 1em;
              line-height: $base-line-height;
              min-width: 100px; // table cells visible
              padding: 0.2em; 
  
              &:focus {
                //border-color: $main-color;
                outline: none;
                //border: 1px solid $main-color;
              }
            }
          }
          &.sv_q_matrix {
            td {
              min-width: 10em;
              .sv_q_m_label {
                position: static;
              }
            }
          }
          &.sv_q_matrix_dropdown {
            .sv_qcbc {
              .sv_q_checkbox_control_label {
                margin-right: 1.5em;
              }
            }
          }

          td,
          th {
            text-align: left;
            padding: 0 1em;
          }

          td {
            padding: 0.5em;
          }

          th {
            line-height: 1.3em;
            padding: 0.5em;
            vertical-align: bottom;
            font-weight: bold;
          }
        }

        fieldset.sv_qcbc {
          line-height: $base-line-height;
          padding-top: 0.1em;
        }

        .sv_q_other {
          margin-left: 1em;
        }

        .sv_q_rating {
          line-height: $base-line-height;

          .sv_q_rating_item {
            cursor: pointer;
            word-spacing: -0.3em;
            font-weight: normal;
            display: inline;

            > * {
              word-spacing: initial;
            }

            &.active {
              .sv_q_rating_item_text {
                //background-color: $main-hover-color;
                //border-color: $main-hover-color;
                //color: $body-background-color;
              }
            }
            .sv_q_rating_item_text {
              display: inline-block;
              min-width: $base-line-height;
              padding: 0 0.3em;
              margin-left: -1px;
              //border: 1px solid $header-background-color;
              text-align: center;
              &:hover {
                //border: 1px solid $main-color;
              }
            }
            &:not(:first-child) {
              .sv_q_rating_item_text:not(:hover) {
                border-left-color: transparent;
              }
            }
          }
          .sv_q_rating_min_text {
            margin-right: 0.5em;
          }
          .sv_q_rating_max_text {
            margin-left: 0.5em;
          }
        }
      }

      .sv_nav {
        padding: 1em 0;
        min-height: $base-line-height;
        display: flex;
        justify-content: space-between;

        .sv_start_btn,
        .sv_next_btn,
        .sv_complete_btn {
          float: right;
          margin-left: auto;
        }
        .sv_prev_btn {
          float: left;
        }
      }
    }
  }
}